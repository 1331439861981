import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
// import { useElementSize } from "usehooks-ts";
import IdleScreen from "./components/IdleScreen";
import PlaylistPanel from "./components/PlaylistPanel";
import TopBar from "./components/TopBar";
import { useAppContext } from "./contexts/App.context";
import { SnackbarProvider } from "./contexts/Snackbar.context";
import theme from "./theme";

function App() {
  // const [setRef, { height: bottomBarHeight }] = useElementSize();

  const { data } = useAppContext();
  const fontSize = data.fontSize || 14;

  const appTheme = createTheme({
    ...theme,
    typography: { fontSize },
  });

  return (
    <ThemeProvider theme={appTheme}>
      <SnackbarProvider>
        <CssBaseline />
        <Box sx={{ background: "default" }}>
          <TopBar />
          <Box
            sx={{
              paddingBottom: `100px`,
            }}
          >
            <PlaylistPanel />
          </Box>
          {/* <BottomBar ref={setRef} /> */}
          <IdleScreen />
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
