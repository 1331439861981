import HistoryIcon from "@mui/icons-material/History"; // Icon for the front
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { FC } from "react";

interface RecentSearchesProps {
  searches: string[];
  onSearchSelect: (search: string) => void;
}

const RecentSearches: FC<RecentSearchesProps> = ({
  searches,
  onSearchSelect,
}) => {
  return (
    <List sx={{}}>
      {searches.length > 0 && (
        <Typography
          variant="h6"
          sx={{ marginBottom: (theme: Theme) => theme.spacing(1) }}
        >
          Recent searches
        </Typography>
      )}

      {searches.map((search, index) => (
        <ListItem
          button
          key={index}
          onClick={() => onSearchSelect(search)}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText
            primary={search}
            primaryTypographyProps={{ noWrap: true }}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flex: 1,
            }}
          />
          <ListItemSecondaryAction>
            {/* <IconButton edge="end" onClick={() => onSearchSelect(search)}>
              <ArrowForwardIosIcon />
            </IconButton> */}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default RecentSearches;
