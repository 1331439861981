import { SocketEvent } from "@common/SocketEvents";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Modal,
  ModalProps,
  Slider,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useAppContext } from "../contexts/App.context";
import { useSocket } from "../contexts/Socket.context";

interface SettingsModalProps extends Omit<ModalProps, "children"> {}

const defaultSettings = {
  musicPlayControlLocked: false,
  playlistSelectLock: false,
  playlistDeleteLock: false,
  autoRemovePlayedTracks: true,
  skipDeleteThreshold: 0,
  administratorOverride: false,
  askWhoSing: false,
};

const SettingsModal: FC<SettingsModalProps> = (modalProps) => {
  const { roomId, sendRequest } = useSocket();
  const { data, setData } = useAppContext();
  const { administratorOverride } = data;
  const {
    playlistData: { playerSettings },
  } = data;

  const {
    musicPlayControlLocked,
    showIdleScreen,
    playlistSelectLock,
    playlistDeleteLock,
    playlistSearchLock,
    autoRemovePlayedTracks,
    skipDeleteThreshold,
    askWhoSing,
  } = {
    ...defaultSettings,
    ...playerSettings,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateSettings = async (partialData: SetStateAction<any>) => {
    const updatedSettings = {
      ...defaultSettings,
      ...playerSettings,
      ...partialData,
    };

    try {
      if (Object.keys(partialData).includes("administratorOverride")) {
        setData("administratorOverride", partialData["administratorOverride"]);
      } else {
        await sendRequest(SocketEvent.UPDATE_PLAYER_SETTINGS, updatedSettings);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [newRoomId, setNewRoomID] = useState(roomId); // State for Room ID
  const [fontSize, setFontSize] = useState(data.fontSize); // State for Room ID

  const handleCloseModal = (
    event: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    setNewRoomID(roomId);
    modalProps.onClose && modalProps.onClose(event, reason);
  };

  const [showWarningModal, setShowWarningModal] = useState(false);

  // Function to handle Apply button click
  const handleApplyClick = () => {
    setShowWarningModal(true);
  };

  const handleCloseWarningModal = (confirm: boolean) => {
    if (confirm) {
      setData("roomId", newRoomId);
      // Apply changes
      console.log("Change Room to", newRoomId);
      const newUrl = `${window.location.pathname}?roomId=${encodeURIComponent(
        newRoomId
      )}`;
      window.location.replace(newUrl);
    }

    setShowWarningModal(false);
  };

  const hiddenDivRef = useRef(null);

  const handleBlurAll = () => {
    if (hiddenDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hiddenDivRef.current?.blur();
    }
  };

  useEffect(() => {
    handleBlurAll();
  }, [modalProps.open]);

  const formControlSx = {
    paddingY: 2,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      <Modal
        sx={{
          background: "rgba(0,0,0,.85)",
          maxHeight: "100%",
          overflowY: "auto",
          display: modalProps.open ? "block" : "none",
        }}
        keepMounted={true}
        {...modalProps}
        onClose={handleCloseModal}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            ref={hiddenDivRef}
            tabIndex={-1}
            sx={{ width: 0, height: 0, overflow: "hidden" }}
          />
          <AppBar position="sticky" sx={{ background: "rgba(0,0,0,.9)" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={() => handleCloseModal({}, "escapeKeyDown")}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography>Settings</Typography>
            </Toolbar>
          </AppBar>
          <Container
            sx={{
              width: "100%",
              paddingX: 4,
              paddingY: 2,
            }}
          >
            <Typography variant="h5">Client Settings</Typography>
            <FormControl component="fieldset">
              <FormGroup>
                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!musicPlayControlLocked}
                        onChange={(e) =>
                          handleUpdateSettings({
                            musicPlayControlLocked: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Music Play Control Locked"
                  />
                  <Typography variant="caption">
                    Prevents users from manually playing, skipping or pausing
                    music tracks.
                  </Typography>
                </Box>

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!playlistSelectLock}
                        onChange={(e) =>
                          handleUpdateSettings({
                            playlistSelectLock: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Playlist Select Lock"
                  />
                  <Typography variant="caption">
                    Users are not allowed to select different songs in the
                    playlist.
                  </Typography>
                </Box>

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!playlistDeleteLock}
                        onChange={(e) =>
                          handleUpdateSettings({
                            playlistDeleteLock: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Playlist Delete Lock"
                  />
                  <Typography variant="caption">
                    Users are not allowed to delete a songs from the playlist.
                  </Typography>
                </Box>

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!playlistSearchLock}
                        onChange={(e) =>
                          handleUpdateSettings({
                            playlistSearchLock: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Playlist Search Lock"
                  />
                  <Typography variant="caption">
                    Users are not allowed to search a songs and queue.
                  </Typography>
                </Box>

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!showIdleScreen}
                        onChange={(e) =>
                          handleUpdateSettings({
                            showIdleScreen: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Show Idle Screen"
                  />
                  <Typography variant="caption">
                    Show Idle Screen for Marketing purposes
                  </Typography>
                </Box>

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!askWhoSing}
                        onChange={(e) =>
                          handleUpdateSettings({
                            askWhoSing: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Ask who sing"
                  />
                  <Typography variant="caption">
                    Show a dialog asking who sing the song.
                  </Typography>
                </Box>

                {false && (
                  <Box sx={formControlSx}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!autoRemovePlayedTracks}
                          onChange={(e) =>
                            handleUpdateSettings({
                              autoRemovePlayedTracks: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Auto-Remove Played Tracks"
                    />
                    <Typography variant="caption">
                      Automatically removes tracks once they've been played or
                      if the user advances to the next song.
                    </Typography>
                  </Box>
                )}

                {false && (
                  <Box sx={formControlSx}>
                    <FormControl>
                      <FormLabel>Skip & Delete Threshold</FormLabel>
                      <Slider
                        value={skipDeleteThreshold || 0}
                        onChange={(_e, value) =>
                          handleUpdateSettings({
                            skipDeleteThreshold: value as number,
                          })
                        }
                        step={10}
                        marks
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                      />
                      <Typography variant="caption">
                        Sets a threshold for automatic deletion of a track if
                        it's skipped after being played to a certain percentage.
                        Setting the value to 0 will delete all the time.
                      </Typography>
                    </FormControl>
                  </Box>
                )}

                <Box sx={formControlSx}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!administratorOverride}
                        onChange={(e) =>
                          handleUpdateSettings({
                            administratorOverride: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Administrator Override"
                  />
                  <Typography variant="caption">
                    Allows an administrator to bypass the restrictions set above
                    on this device.
                  </Typography>
                </Box>
              </FormGroup>
            </FormControl>

            <Typography variant="h5" sx={{ marginTop: 4 }}>
              Room Settings
            </Typography>
            <FormControl
              component="fieldset"
              sx={{
                width: "100%",
              }}
            >
              <FormGroup>
                <Box sx={{ ...formControlSx, gap: 1, flexDirection: "row" }}>
                  <TextField
                    label="Room ID"
                    variant="outlined"
                    value={newRoomId}
                    onChange={(e) => setNewRoomID(e.target.value)}
                    //   helperText="Enter the unique identifier for the room.11"
                    fullWidth
                  />
                  <Button
                    sx={{}}
                    disabled={roomId === newRoomId}
                    variant="contained"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </Button>
                </Box>
                <Box sx={{ ...formControlSx, gap: 1, flexDirection: "row" }}>
                  <TextField
                    label="Font Size"
                    variant="outlined"
                    type="number"
                    value={fontSize}
                    onChange={(e) => setFontSize(parseInt(e.target.value, 10))}
                    //   helperText="Enter the unique identifier for the room.11"
                    fullWidth
                  />
                  <Button
                    sx={{}}
                    disabled={fontSize === data?.fontSize}
                    variant="contained"
                    onClick={() => {
                      setData("fontSize", fontSize);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </FormGroup>
            </FormControl>
          </Container>
        </Box>
      </Modal>
      <Dialog
        open={showWarningModal}
        onClose={() => handleCloseWarningModal(false)}
      >
        <DialogTitle>Change Room ID?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing the Room ID may lose all the other settings. Are you sure
            you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseWarningModal(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleCloseWarningModal(true)}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettingsModal;
