import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  InputBase,
  Modal,
  ModalProps,
  Toolbar,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  FC,
  FormEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppContext } from "../contexts/App.context";
import RecentSearches from "./RecentSearches";
import SearchResultPanel from "./SearchResultPanel";

interface SearchModalProps extends Omit<ModalProps, "children"> {
  children?: ReactNode;
}

const SearchModal: FC<SearchModalProps> = ({ children, ...modalProps }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [submittedTerm, setSubmittedTerm] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  // const [searchBarRef, { width: searchBarWidth }] = useElementSize();

  const {
    data: { recentSearches },
    setData,
  } = useAppContext();

  useEffect(() => {
    if (modalProps.open && inputRef.current) {
      setSearchTerm("");
      inputRef.current.focus();
    }
  }, [modalProps.open]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!searchTerm) {
      return;
    }

    if (modalProps.open && inputRef.current) {
      inputRef.current.blur();
    }

    setData("recentSearches", (prevSearches) => {
      const updatedSearches = [
        searchTerm,
        ...prevSearches.filter((search) => search !== searchTerm),
      ];
      return updatedSearches.slice(0, 10); // Keep only the first 5 items
    });

    setSubmittedTerm(searchTerm);
  };

  const handleRecentSearchClick = (search: string): void => {
    setSearchTerm(search);
    setSubmittedTerm(search);
  };

  const handleClearSearch = (): void => {
    setSearchTerm("");
  };

  const handleCloseModal = (
    event: object,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    setSearchTerm("");
    setSubmittedTerm("");
    modalProps.onClose && modalProps.onClose(event, reason);
  };

  return (
    <Modal
      sx={{
        background: "rgba(0,0,0,.85)",
        maxHeight: "100%",
        overflowY: "auto",
        display: modalProps.open ? "block" : "none",
      }}
      keepMounted={true}
      {...modalProps}
      onClose={handleCloseModal}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <AppBar position="sticky" sx={{ background: "rgba(0,0,0,.9)" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => handleCloseModal({}, "escapeKeyDown")}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
            <Box
              // ref={searchBarRef}
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                marginLeft: 1,
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{ flexGrow: 1, display: "flex", alignItems: "center" }}
              >
                <InputBase
                  placeholder="Search…"
                  autoFocus
                  value={searchTerm}
                  onChange={(e: {
                    target: { value: SetStateAction<string> };
                  }) => setSearchTerm(e.target.value)}
                  style={{
                    flex: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                    borderRadius: 10,
                    marginRight: ".4rem",
                  }}
                  inputProps={{
                    "aria-label": "search",
                    ref: inputRef,
                    style: { padding: "10px 20px" },
                  }}
                  endAdornment={
                    searchTerm && (
                      <IconButton
                        onClick={handleClearSearch}
                        aria-label="clear search"
                        size="small"
                        sx={{ marginRight: (theme: Theme) => theme.spacing(1) }}
                        color="inherit"
                      >
                        <ClearIcon />
                      </IconButton>
                    )
                  }
                />
                <IconButton
                  type="submit"
                  aria-label="search"
                  size="large"
                  edge="end"
                  color="inherit"
                >
                  <SearchIcon />
                </IconButton>
              </form>
            </Box>
          </Toolbar>
        </AppBar>

        <Container
          sx={{
            width: "100%",
          }}
        >
          {!submittedTerm && (
            <RecentSearches
              searches={recentSearches}
              onSearchSelect={handleRecentSearchClick}
            />
          )}
          <SearchResultPanel
            query={submittedTerm}
            onSelectMusicItem={() => handleCloseModal({}, "escapeKeyDown")}
          />
          {children}
        </Container>
      </Box>
    </Modal>
  );
};

export default SearchModal;
