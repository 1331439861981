import { createTheme } from "@mui/material";

// Importing Roboto font weights
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      // main: "#FF0000", // YouTube's iconic red
      main: "#007c00", // YouTube's iconic red
    },
    secondary: {
      main: "#FFFFFF", // White for secondary elements
    },
    background: {
      default: "#000000", // Black background
      paper: "#121212", // Slightly lighter black for paper elements
    },
    text: {
      primary: "#FFFFFF", // White text for readability
      secondary: "rgba(255, 255, 255, 0.7)", // Slightly dimmed white for secondary text
    },
    error: {
      main: "#f44336",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      // fontSize: "2rem",
      fontWeight: 500,
    },
    h2: {
      // fontSize: "1.75rem",
      fontWeight: 500,
    },
    h3: {
      // fontSize: "1.5rem",
      fontWeight: 500,
    },
    h4: {
      // fontSize: "1.25rem",
      fontWeight: 500,
    },
    h5: {
      // fontSize: "1rem",
      fontWeight: 500,
    },
    h6: {
      // fontSize: "0.875rem",
      fontWeight: 500,
    },
    subtitle1: {
      // fontSize: "0.875rem",
      fontWeight: 400,
    },
    subtitle2: {
      // fontSize: "0.75rem",
      fontWeight: 400,
    },
    body1: {
      // fontSize: "0.875rem",
      fontWeight: 400,
    },
    body2: {
      // fontSize: "0.75rem",
      fontWeight: 400,
    },
    button: {
      // fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "uppercase",
      color: "#FFFFFF", // Ensuring buttons are white
    },
    caption: {
      // fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      // fontSize: "0.625rem",
      fontWeight: 400,
      textTransform: "uppercase",
    },
  },
  components: {
    // Customizing components to better match YouTube's look
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#212121", // AppBar color similar to YouTube's
        },
      },
    },
    // ... other component overrides
  },
});

export default theme;
