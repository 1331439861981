// SocketContext.tsx
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import io, { Socket } from "socket.io-client";
import { Subject } from "rxjs";
import {
  SocketEvent,
  EventPayloads,
  EventResponses,
  SocketType,
} from "@common/SocketEvents";
import { Alert, Backdrop, Button, CircularProgress } from "@mui/material";
import { useAppContext } from "./App.context";

interface ISocketContext {
  socket: Socket;
  sendMessage: (message: EventPayloads[SocketEvent.MESSAGE]) => void;
  sendRequest: <T extends keyof EventPayloads>(
    event: T,
    data: EventPayloads[T]
  ) => Promise<EventResponses[T]>;
  messageSubject: Subject<EventPayloads[SocketEvent.MESSAGE]>;
  roomId: string;
}

const SocketContext = createContext<ISocketContext>({} as ISocketContext);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    data: { roomId },
  } = useAppContext();

  // const getRoomIdFromURL = () => {
  //   // const queryParams = new URLSearchParams(window.location.search);
  //   // return queryParams.get("roomId") || "defaultRoom"; // Fallback to 'defaultRoom' if roomId is not provided
  //   return data.roomId;
  // };

  // const roomId = getRoomIdFromURL();

  const socket: Socket = useMemo(
    () =>
      io(import.meta.env.VITE_SERVER_URL, {
        query: { roomId: roomId, mode: SocketType.CLIENT },
      }),
    []
  );
  const messageSubject = new Subject<EventPayloads[SocketEvent.MESSAGE]>();
  const [isConnected, setConnection] = useState(false);
  const [connectionError, setConnectionError] = useState("");
  const { setData } = useAppContext();

  useEffect(() => {
    // Event listener for 'message' event
    socket.on(
      SocketEvent.MESSAGE,
      (message: EventResponses[SocketEvent.MESSAGE]) => {
        messageSubject.next(message);
      }
    );
    socket.on(SocketEvent.CONNECT, () => {
      setConnection(true);
      setConnectionError(""); // Reset connection error on successful connect
    });

    socket.on(SocketEvent.DISCONNECT, () => {
      setConnection(false);
    });

    socket.on(SocketEvent.CONNECT_ERROR, (error) => {
      socket.disconnect();
      setConnection(false);
      setConnectionError("Connection Failed: " + error);
    });

    socket.on(SocketEvent.ERROR, (error) => {
      socket.disconnect();
      setConnection(false);
      setConnectionError("Error: " + error);
    });

    socket.on(
      SocketEvent.PLAYLIST_UPDATE,
      (message: EventResponses[SocketEvent.PLAYLIST_UPDATE]) => {
        setData("playlistData", message);
        // console.log(message.playerMeta.isPlaying)
        // console.log(`Playlist Updated with ${JSON.stringify(message)}`);
      }
    );

    // Cleanup on unmount
    return () => {
      socket.off(SocketEvent.MESSAGE);
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // Function to send messages
  const sendMessage = (message: EventPayloads[SocketEvent.MESSAGE]) => {
    socket.emit(SocketEvent.MESSAGE, message);
  };

  // Function to send requests and receive a promise-based response
  const sendRequest = <T extends keyof EventPayloads>(
    event: T,
    data: EventPayloads[T]
  ): Promise<EventResponses[T]> => {
    return new Promise((resolve) => {
      socket.emit(event, data, (response: EventResponses[T]) => {
        return resolve(response);
      });
    });
  };

  const [retryTimer, setRetryTimer] = useState<number | null>(null);
  useEffect(() => {
    // If there's a connection error and no active timer, set a timer
    if (connectionError && !retryTimer) {
      const timer = setTimeout(() => {
        retryConnection();
      }, 10000); // 10 seconds
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setRetryTimer(timer);
    }

    // Cleanup timer on unmount or when error is resolved
    return () => {
      if (retryTimer) {
        clearTimeout(retryTimer);
        setRetryTimer(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionError, retryTimer]);

  const retryConnection = () => {
    if (retryTimer) {
      clearTimeout(retryTimer);
      setRetryTimer(null);
    }

    setConnectionError("");
    socket.disconnect();
    socket.connect(); // Attempt to reconnect
  };

  // Expose the socket, sendMessage function, sendRequest, and messageSubject
  return (
    <SocketContext.Provider
      value={{ socket, sendMessage, sendRequest, messageSubject, roomId }}
    >
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!isConnected}
      >
        {!connectionError ? (
          <CircularProgress color="inherit" />
        ) : (
          <Alert
            severity="error"
            action={
              <Button color="inherit" size="small" onClick={retryConnection}>
                Retry Now
              </Button>
            }
          >
            Connection Failed. Will retry in 10 seconds.
            <br />
            <small>{connectionError}asbv</small>
          </Alert>
        )}
      </Backdrop>
      {children}
    </SocketContext.Provider>
  );
};
