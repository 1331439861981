import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import App from "./App.tsx";
import { AppContextProvider } from "./contexts/App.context.tsx";
import { SocketProvider } from "./contexts/Socket.context.tsx";
import "./main.scss";
registerSW({ immediate: true });

Sentry.init({
  dsn: "https://5107808faeba0f8834210e7ca9078e47@o1331366.ingest.sentry.io/4506566980730880",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/koremote\.pages\.dev\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "dark",
      showBranding: false,
      showName: false,
      showEmail: false,
      formTitle: "Give Feedback",
      buttonLabel: "",
      submitButtonLabel: "Submit",
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root")!;
rootElement.style.opacity = "0";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <AppContextProvider>
    <SocketProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SocketProvider>
  </AppContextProvider>
);

window.addEventListener("load", () => {
  rootElement.style.transition = "opacity 0.5s ease-out";
  rootElement.style.opacity = "1";
});
