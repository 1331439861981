import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSocket } from "../contexts/Socket.context";
import MusicItem from "./MusicItem";
import { useSnackbar } from "../contexts/Snackbar.context";
import { truncateString } from "../helper/utils";
import { SocketEvent, VideoDetails } from "@common/SocketEvents";
import { useAppContext } from "../contexts/App.context";
import { uniqBy } from "lodash";
import "./SearchResultPanel.scss";

interface SingerDetailModalProps {
  open: boolean;
  songTitle?: string;
  handleModalClose: () => void;
  handleModalSubmit: (data: { name: string }) => void;
}

const SingerDetailModal: React.FC<SingerDetailModalProps> = ({
  open,
  songTitle,
  handleModalClose,
  handleModalSubmit,
}) => {
  const [name, setName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const truncateTitle = (title: string, maxLength: number) => {
    return title.length > maxLength
      ? `${title.substring(0, maxLength)}...`
      : title;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (open && inputRef.current) {
      timer = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    return () => clearTimeout(timer);
  }, [open]);

  const handleDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      // Set 'open' to false, however you would do that with your particular code.
      handleModalClose();
    }
  };

  return (
    <Dialog
      disableRestoreFocus
      open={open}
      className="dialog-container"
      onClose={handleDialogClose}
    >
      <DialogTitle>
        <small>{truncateTitle(songTitle ?? "", 40)}</small>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setName("");
            inputRef.current?.blur();
            handleModalSubmit({ name });
          }}
        >
          <TextField
            inputRef={inputRef}
            margin="dense"
            autoComplete="off"
            autoFocus={true}
            label="Who is singing?"
            type="text"
            autoCapitalize="words"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            inputRef.current?.blur();
            setName("");
            handleModalClose();
          }}
          color="error"
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            inputRef.current?.blur();
            setName("");
            handleModalSubmit({ name });
          }}
          color="primary"
        >
          Add to Playlist
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface SearchResultPanelProps {
  query: string;
  onSelectMusicItem: () => void;
}

const SearchResultPanel: React.FC<SearchResultPanelProps> = ({
  query,
  onSelectMusicItem,
}) => {
  const { sendRequest } = useSocket();
  const [blockScreen, setBlockScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageToken, setPageToken] = useState<string | undefined>();
  const [videos, setVideos] = useState<VideoDetails[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver>();
  const addSnackbar = useSnackbar();
  const { data } = useAppContext();
  const [karakokeMode, setKaraokeMode] = useState(data.isKaraokeMode);

  // State for modal
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<VideoDetails | null>(null);

  const askWhoSing = data.playlistData?.playerSettings?.askWhoSing;

  useEffect(() => {
    setKaraokeMode(data.isKaraokeMode);
  }, [data.isKaraokeMode]);

  const onSearch = async (q: string, pt?: string) => {
    setIsLoading(true);
    const result = await sendRequest(SocketEvent.SEARCH, {
      query: `${q} ${karakokeMode ? "karaoke" : ""}`,
      pageToken: pt,
    });
    setPageToken(result.nextPageToken);
    const uniqueVideos = uniqBy(result.items, "id");
    setVideos((prevVideos) => [...prevVideos, ...uniqueVideos]);
    setIsLoading(false);
    setHasMore(result.nextPageToken != null);
  };

  const lastVideoElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          onSearch(query, pageToken);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  useEffect(() => {
    setVideos([]);
    setPageToken(undefined);
    if (query.trim() !== "") {
      umami.track("search", { query: query });
      onSearch(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setBlockScreen(false);
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    setSelectedVideo(null);
  };

  const handleModalSubmit = async (
    data: { name?: string },
    _video?: VideoDetails
  ) => {
    const targetVideo = selectedVideo || _video;
    const { name } = data;

    if (targetVideo) {
      setBlockScreen(true);
      await sendRequest(SocketEvent.ADD_TO_PLAYLIST, {
        videoDetail: { ...targetVideo, singer: name },
      });
      addSnackbar(
        `Added ${truncateString(targetVideo.title, 50)} to playlist.`,
        "success"
      );
      umami.track("add_to_playlist", { video: targetVideo.title });
      setBlockScreen(false);
      onSelectMusicItem?.();
      handleModalClose();
    }
  };

  const onHandleAddToPlaylist = (video: VideoDetails) => {
    if (data.isKaraokeMode && askWhoSing) {
      setSelectedVideo(video);
      setOpen(true);
    } else {
      handleModalSubmit({}, video);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: (theme) => theme.spacing(1),
      }}
    >
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={blockScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          {videos.map((video) => {
            return (
              <MusicItem
                key={video.id}
                video={video}
                onMusicItemClick={onHandleAddToPlaylist}
              />
            );
          })}

          {videos.length > 0 && (
            <div ref={lastVideoElementRef} key={"lastVideoElem"}></div>
          )}

          {/* Loading Spinner */}
          <Grid
            item
            xs={12}
            sx={{
              visibility: isLoading ? "visible" : "hidden",
              paddingTop: (theme) => theme.spacing(2),
              paddingBottom: (theme) => theme.spacing(2),
              marginTop: (theme) => theme.spacing(4),
              marginBottom: (theme) => theme.spacing(2),
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flex: "1",
            }}
          >
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Container>
      <SingerDetailModal
        open={open}
        handleModalClose={handleModalClose}
        handleModalSubmit={handleModalSubmit}
        songTitle={selectedVideo?.title}
      />
    </Box>
  );
};

export default SearchResultPanel;
