import { VideoDetails } from "@common/SocketEvents";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { SxProps, Theme } from "@mui/material/styles";
import React, { MouseEvent, useMemo } from "react";
import sound from "../assets/sound-stamp.gif";
import { useAppContext } from "../contexts/App.context";

interface MusicItemProps {
  video: VideoDetails;
  onMusicItemClick?: (video: VideoDetails) => void;
  onMusicItemDelete?: () => void;
  viewType?: "grid" | "list" | "auto";
  sx?: SxProps;
  isPlaying?: boolean;
}

const MusicItem: React.FC<MusicItemProps> = ({
  video,
  viewType = "auto",
  onMusicItemClick,
  onMusicItemDelete,
  sx,
  isPlaying = false,
}) => {
  const { data } = useAppContext();
  const { administratorOverride } = data;
  const { playerSettings } = data.playlistData;

  function handleOnClick(event: MouseEvent<HTMLButtonElement>): void {
    event?.stopPropagation();
    onMusicItemClick?.(video);
  }

  function convertToMinutes(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  const gridBreakpoints = useMemo(() => {
    if (viewType === "grid") {
      return {
        xs: 6,
        md: 4,
        lg: 3,
      };
    }
    if (viewType === "list") {
      return {
        xs: 12,
        md: 12,
        lg: 12,
      };
    }
    return {
      xs: 12,
      md: 6,
      lg: 6,
    };
  }, [viewType]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item {...gridBreakpoints} sx={sx}>
      <Card
        sx={{
          mb: 0,
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardActionArea
          onClick={(
            e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
          ) => handleOnClick(e)}
          sx={{
            display: "flex",
            padding: 0,
            flex: 1,
            overflow: "hidden",
            alignItems: "stretch",
            position: "relative",
          }}
        >
          <CardMedia
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              position: "relative",
            }}
          >
            <Box
              component={"img"}
              sx={{
                minWidth: 120,
                width: 120,
                height: "100%",
                objectFit: "cover",
              }}
              src={video.thumbnail} // Assuming the image URL is stored in video.thumbnail
              alt={video.title}
            />
            <Fade in={isPlaying}>
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  margin: 0,
                  padding: "30px",
                  background: "rgba(0,0,0,.4)",
                  display: "flex", // Add this line
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <Box
                  component={"img"}
                  sx={{
                    width: "60%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  src={sound}
                />
              </Box>
            </Fade>
          </CardMedia>
          <CardContent
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexGrow: 1,
              maxWidth: "100%",
              paddingTop: (theme: Theme) => theme.spacing(1),
              paddingBottom: (theme: Theme) => `${theme.spacing(1)} !important`,
              paddingRight: (theme: Theme) => theme.spacing(1),
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                fontWeight: "500",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {video.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {video.channelName} {video?.singer && `🎤 ${video?.singer}`}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {convertToMinutes(video.duration)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            flexShrink: 1,
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        >
          {(!playerSettings?.playlistDeleteLock || administratorOverride) &&
            onMusicItemDelete && (
              <>
                <IconButton
                  aria-label="more"
                  size="large"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      style: {
                        paddingLeft: "1.4rem",
                        paddingRight: "1.4rem",
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onMusicItemDelete?.();
                    }}
                  >
                    <Typography sx={{ width: "100%" }}>Delete</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default MusicItem;
