export function truncateString(
  str: string,
  maxLength: number = 50,
  ellipsis: boolean = true
): string {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + (ellipsis ? "..." : "");
}
