import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { IDLE_SCREEN as IDLE_SCREEN_TIMEOUT } from "../App.constant";
import { Backdrop, Box, Typography } from "@mui/material";
import banner from "../assets/banner.jpg";
import moment from "moment";
import { useAppContext } from "../contexts/App.context";

const IdleScreen = () => {
  const [idle, setIdle] = useState(false);

  const { data } = useAppContext();
  const showIdleScreen = !!data?.playlistData?.playerSettings?.showIdleScreen;

  useIdleTimer({
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
    onAction: () => setIdle(false),
    timeout: IDLE_SCREEN_TIMEOUT,
    throttle: 500,
  });

  const handleClose = () => {
    setIdle(false);
  };

  const isExpired = moment().isAfter(moment("2024-05-19"));

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1000 }}
      open={showIdleScreen && idle && !isExpired}
      onClick={handleClose}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: "90%",
          maxHeight: "90%",
          padding: "10%",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "1em",
            width: "auto",
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
          }}
          component="img"
          loading="lazy"
          src={banner}
        ></Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 300,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Touch anywhere dismiss
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default IdleScreen;
