import { SocketEvent, VideoDetails } from "@common/SocketEvents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useState } from "react";
import { useAppContext } from "../contexts/App.context";
import { useSocket } from "../contexts/Socket.context";
import MusicItem from "./MusicItem";
import "./PlaylistPanel.scss";
import { useSnackbar } from "../contexts/Snackbar.context";

const PlaylistPanel = () => {
  const { data } = useAppContext();
  const addSnackbar = useSnackbar();

  const { administratorOverride } = data;
  const { playerMeta, playlistQueue, playerSettings } = data.playlistData;
  const currentVideo = playlistQueue?.[playerMeta.currentIndex!];
  const { sendRequest } = useSocket();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleConfirmPlay = () => {
    sendRequest(SocketEvent.PLAYLIST_PLAY, selectedIndex);
    setOpenDialog(false);
  };

  const isIndexPlaying = (index: number, video: VideoDetails) => {
    const currentVideoExist = !!playlistQueue.find(
      (i) => i.id === currentVideo?.id
    );

    if (currentVideoExist) {
      return (
        index === playerMeta.currentIndex &&
        !playerMeta.isAutoPlay &&
        playerMeta?.currentVideo?.id === video.id
      );
    }
    return false;
  };

  const handleOnMusicItemClick = (index: number, video: VideoDetails) => {
    if (playerSettings?.playlistSelectLock && !administratorOverride) {
      addSnackbar("Skipping music is disabled", "warning");
      return;
    }

    if (isIndexPlaying(index, video)) {
      // do nothing
    } else {
      setSelectedIndex(index);
      setOpenDialog(true);
      // if (playlistQueue.find((i) => i.id === playerMeta.currentVideo?.id)) {
      // } else {
      //   sendRequest(SocketEvent.PLAYLIST_PLAY, index);
      // }
    }
  };

  const [panelMenuAnchorEl, setPanelMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const openPanelMenu = Boolean(panelMenuAnchorEl);

  const handlePanelMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPanelMenuAnchorEl(event.currentTarget);
  };

  const handlePanelMenuClose = () => {
    setPanelMenuAnchorEl(null);
  };

  const [confirmDeleteAllDialogOpen, setConfirmDeleteAllDialogOpen] =
    useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const confirmDeleteAll = () => {
    sendRequest(SocketEvent.REMOVE_FROM_PLAYLIST, { videoIndex: -1 });
    setConfirmDeleteAllDialogOpen(false);
  };

  const handleOnMusicItemDelete = (index: number) => {
    // sendRequest(SocketEvent.REMOVE_FROM_PLAYLIST, { videoIndex: index });
    handlePanelMenuClose();
    if (isIndexPlaying(index, playlistQueue[index])) {
      setDeleteIndex(index);
      setConfirmDeleteDialogOpen(true);
    } else {
      sendRequest(SocketEvent.REMOVE_FROM_PLAYLIST, { videoIndex: index });
    }
  };

  const onMusicItemDeleteAll = () => {
    // sendRequest(SocketEvent.REMOVE_FROM_PLAYLIST, { videoIndex: -1 });
    handlePanelMenuClose();
    setConfirmDeleteAllDialogOpen(true);
  };

  const confirmDeleteSong = () => {
    sendRequest(SocketEvent.REMOVE_FROM_PLAYLIST, {
      videoIndex: deleteIndex,
    });
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: (theme: Theme) => theme.spacing(1),
      }}
    >
      <>
        <Dialog
          open={confirmDeleteAllDialogOpen}
          onClose={() => setConfirmDeleteAllDialogOpen(false)}
        >
          <DialogTitle>{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete all songs from the playlist?
              <br />
              This action cannot be undone. Additionally, if there is any music
              currently playing, it will be skipped as a result of this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setConfirmDeleteAllDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={confirmDeleteAll} color="primary" autoFocus>
              Delete All
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirm Delete Song Dialog */}
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={() => setConfirmDeleteDialogOpen(false)}
        >
          <DialogTitle>{"Confirm Song Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-delete-song-description">
              The song you are trying to delete is currently playing.
              <br />
              Are you sure you want to remove it from the playlist?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setConfirmDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={confirmDeleteSong} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>{"Confirm Skip"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to skip the current song?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleConfirmPlay} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Container sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          {(playlistQueue || []).length > 0 && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: 0,
                    alignItems: "center",
                    display: "flex",
                    flexGrow: 1,
                  }}
                >
                  Up Next
                </Typography>
                {(!playerSettings?.playlistDeleteLock ||
                  administratorOverride) && (
                  <>
                    <IconButton onClick={handlePanelMenuOpen} size="large">
                      <ExpandMoreIcon></ExpandMoreIcon>
                    </IconButton>
                    <Menu
                      anchorEl={panelMenuAnchorEl}
                      keepMounted
                      open={openPanelMenu}
                      onClose={handlePanelMenuClose}
                      slotProps={{
                        paper: {
                          style: {
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={() => onMusicItemDeleteAll()}>
                        <Typography sx={{ width: "100%" }}>
                          Delete All
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
              {playlistQueue.map(
                (video, index) =>
                  video.id && (
                    <MusicItem
                      viewType="list"
                      key={`${index}-${video.id}`}
                      video={video}
                      isPlaying={isIndexPlaying(index, video)}
                      onMusicItemClick={() =>
                        handleOnMusicItemClick(index!, video)
                      }
                      onMusicItemDelete={() => handleOnMusicItemDelete(index!)}
                    />
                  )
              )}
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default PlaylistPanel;
