import { Snackbar, SnackbarCloseReason, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, {
  SyntheticEvent,
  createContext,
  useContext,
  useState,
} from "react";

// Define the shape of individual snackbar message
interface SnackbarMessage {
  message: string;
  severity: "success" | "info" | "warning" | "error";
  key: number;
}

// Define the shape of the Snackbar context data
interface SnackbarContextData {
  snackPack: SnackbarMessage[];
  addSnackbar: (
    message: string,
    severity: "success" | "info" | "warning" | "error"
  ) => void;
}

const defaultValue: SnackbarContextData = {
  snackPack: [],
  addSnackbar: () => {},
};

const SnackbarContext = createContext<SnackbarContextData>(defaultValue);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>(
    defaultValue.snackPack
  );
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (snackPack.length && !open) {
      // Set the next message from the snackPack
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && open) {
      // Close the current snackbar to show the next one
      setOpen(false);
    }
  }, [snackPack, open]);

  const handleClose = (
    event: Event | SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ): void => {
    event?.stopPropagation();
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const addSnackbar = (
    message: string,
    severity: "success" | "info" | "warning" | "error" = "info"
  ) => {
    setSnackPack((prev) => [
      ...prev,
      { message, severity, key: new Date().getTime() },
    ]);
  };

  const contextValue = {
    snackPack,
    addSnackbar,
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        key={messageInfo?.key}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
      >
        {messageInfo ? (
          <Alert
            onClose={handleClose}
            severity={messageInfo.severity}
            sx={{ color: "#fff" }}
          >
            <Typography color={"white"}>{messageInfo.message}</Typography>
          </Alert>
        ) : undefined}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

// Custom hook to access the Snackbar context
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context.addSnackbar;
};
